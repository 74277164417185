import {
  CALENDAR_EVENTS_REQUEST,
  CALENDAR_EVENTS_SUCCESS,
  CALENDAR_EVENTS_FAILURE,
  CALENDAR_EVENTS_DESTROY_SUCCESS,
} from '../actions/calendarEvents'

const DEFAULT_STATE = {
  gettingCalendarEvents: true,
  calendarEvents: [],
}

export function calendarEvents(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CALENDAR_EVENTS_REQUEST:
      return Object.assign({ calendarEvents: [] }, state, DEFAULT_STATE)
    case CALENDAR_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        gettingCalendarEvents: false,
        calendarEvents: action.response.data
      })
    case CALENDAR_EVENTS_FAILURE:
      return Object.assign({}, state, DEFAULT_STATE)
    case CALENDAR_EVENTS_DESTROY_SUCCESS:
      let { calendarEvents } = state
      let deletedEvent = action.response.data
      let remainingCalendarEvents = []
      calendarEvents.forEach((e) => {
        if (e.id != deletedEvent.id) remainingCalendarEvents.push(e)
      })
      return Object.assign({}, state, { calendarEvents: remainingCalendarEvents })
    default:
      return state
  }
}
