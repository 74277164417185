const IMAGE_EXTS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tif', 'tiff', 'heic', 'heif', 'webp']
const VIDEO_EXTS = ['mpg', 'mp4', 'avi', 'mov', 'm4v', '3gp', 'webm', 'mkv', '3g2']
const ZIP_EXTS = ['zip']

export const FileExtensions = {
  image: IMAGE_EXTS,
  video: VIDEO_EXTS,
  zip: ZIP_EXTS,
  all: IMAGE_EXTS.concat(VIDEO_EXTS).concat(ZIP_EXTS),
}
