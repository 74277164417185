import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CalendarEvents from '../components/CalendarEvents'

import { list, destroy } from '../actions/calendarEvents'

import { list as listCategories } from '../actions/categories'

import { Analytics } from '../utilities/Analytics'

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

const advanceByOneWeek = (date) => new Date(date.getTime() + ONE_WEEK)

const oneWeekAgo = (date) => new Date(date.getTime() - ONE_WEEK)

class CalendarEventsContainer extends Component {
  constructor(props) {
    super(props)
    const after = new Date()
    after.setHours(0)
    after.setMinutes(0)
    after.setSeconds(0, 0)
    this.state = { after, before: advanceByOneWeek(after) }
    this.destroyEvent = this.destroyEvent.bind(this)
    this.getEvents = this.getEvents.bind(this)
    this.nextWeek = this.nextWeek.bind(this)
    this.previousWeek = this.previousWeek.bind(this)
  }

  componentDidMount() {
    const { dispatch, frame } = this.props
    dispatch(listCategories(frame.id))
    this.getEvents()
    Analytics.track('Viewed Calendar Events')
  }

  getEvents() {
    const { dispatch, frame } = this.props
    const { after, before } = this.state
    dispatch(list(frame.id, { after, before }))
  }

  nextWeek() {
    let { after, before } = this.state
    after = advanceByOneWeek(after)
    before = advanceByOneWeek(before)
    this.setState({ before, after }, this.getEvents)
  }

  previousWeek() {
    let { after, before } = this.state
    after = oneWeekAgo(after)
    before = oneWeekAgo(before)
    this.setState({ before, after }, this.getEvents)
  }

  destroyEvent(event) {
    if (confirm('Are you sure you want to delete this event off of your calendar?')) {
      const { dispatch, frame } = this.props
      dispatch(destroy(frame.id, event.id))
    }
  }

  render() {
    const {
      frame,
      gettingCategories,
      categories,
      calendarEvents,
      gettingCalendarEvents,
      goToCalendars,
      isAdmin,
      presence,
    } = this.props
    const { after } = this.state
    return (
      <CalendarEvents
        frame={frame}
        isAdmin={isAdmin}
        presence={presence}
        calendarEvents={calendarEvents}
        categories={categories}
        loading={gettingCalendarEvents || gettingCategories}
        weekStart={after}
        goToCalendars={goToCalendars}
        nextWeek={this.nextWeek}
        previousWeek={this.previousWeek}
        destroy={this.destroyEvent}
      />
    )
  }
}

CalendarEventsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  frame: PropTypes.object.isRequired,
  presence: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  gettingCategories: PropTypes.bool.isRequired,
  calendarEvents: PropTypes.array.isRequired,
  gettingCalendarEvents: PropTypes.bool.isRequired,
  goToCalendars: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { frame, presence } = state.frames
  const { isAdmin } = state.session
  const { categories, gettingCategories } = state.categories
  const { calendarEvents, gettingCalendarEvents } = state.calendarEvents
  return {
    frame,
    presence,
    isAdmin,
    categories,
    gettingCategories,
    calendarEvents,
    gettingCalendarEvents,
  }
}

export default connect(mapStateToProps)(CalendarEventsContainer)
